import React, {useEffect, useReducer} from 'react';
import {useHistory} from 'react-router-dom';
import {UserForm} from '../partials/profile/UserForm';
import {Spinner} from '@amzn/awsui-components-react';
import {CharityAPI} from '../api/charity';
import {Button} from '@amzn/awsui-components-react/polaris';
import {UserFormVisibility} from '../partials/profile/UserFormVisibility';

export const CharityEditPage = (props) => {
  const history = useHistory();

  const defaultState = {
    isFetching: true,
    charity: null,
    isSaving: false,
    formFields: {},
    isDeleting: false
  };

  function charityEditReducer(state, action) {
    switch (action.type) {
      case 'fetch_charity_request':
        return {
          ...state,
          isFetching: true
        };
      case 'fetch_charity_success':
        return {
          ...state,
          isFetching: false,
          charity: action.charity,
          formFields: {
            emailField: action.charity.email,
            usernameField: action.charity.username,
            vendorCodeField: action.charity.vendor_code,
            firstNameField: action.charity.first_name,
            lastNameField: action.charity.last_name,
            isActive: action.charity.is_active,
            businessGroups: action.charity.business_groups,
            unitsLimitField: action.charity.units_limit,
            cogsLimitField: action.charity.cogs_limit,
          }
        };
      case 'fetch_charity_error':
        history.push('/manage');
        return {
          ...state,
          isFetching: false
        };
      case 'save_charity_request':
        return {
          ...state,
          isSaving: true
        };
      case 'save_charity_success':
        history.push('/manage');
        return {
          ...state,
          isSaving: false,
          charity: action.charity
        };
      case 'delete_charity_request':
        return {
          ...state,
          isDeleting: true
        };
      case 'delete_charity_success':
        history.push('/manage');
        return {
          ...state,
          isDeleting: false
        };
      case 'save_charity_error':
        return {...state, isSaving: false};
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [state, dispatch] = useReducer(charityEditReducer, defaultState);

  useEffect(() => {
    dispatch({
      type: 'fetch_charity_request'
    });
    CharityAPI.getCharity(props.match.params.username, (response) => dispatch({
      type: 'fetch_charity_success',
      charity: response
    }), (error) => {
      dispatch({
        type: 'fetch_charity_error'
      });
    });
  }, [props.match.params.username]);

  function handleSubmit(formFields) {
    dispatch({
      type: 'save_charity_request'
    });
    CharityAPI.updateCharity(formFields, (response) => dispatch({
      type: 'save_charity_success',
      charity: response
    }), (error) => {
      dispatch({
        type: 'save_charity_error'
      });
    });
  }

  function onClickDeleteUser() {
    dispatch({
      type: 'delete_charity_request'
    });
    CharityAPI.deleteCharity(state.charity.username, (response) => dispatch({
      type: 'delete_charity_success'
    }));
  }

  if (state.isFetching) {
    return (
      <div className='center-text'>
        <Spinner size='large'/>
      </div>
    );
  } else {
    return (
      <div>
        <UserForm
          formFields={state.formFields}
          onSubmitHandler={handleSubmit}
          userFormVisibility={new UserFormVisibility(true, false, true, false, false, false)}
          isSaving={state.isSaving}
        />
        <Button onClick={onClickDeleteUser}>Delete charity</Button>
      </div>
    );
  }
};