import React, {useContext, useEffect, useReducer} from 'react';
import {Auth} from '@aws-amplify/auth';
import {NavLink, useHistory} from 'react-router-dom';
import {Grid, ButtonDropdown} from '@amzn/awsui-components-react/polaris';
import {Amplify} from '@aws-amplify/core';
import config from './config.json';
import {BasketContext} from './contexts/basket';
import {ShoppingBasket} from './partials/ShoppingBasket';

Amplify.configure(config);


const defaultState = {
  dropdownItems: [],
  quantity: 0
};

const defaultDropdownItems = [
  {text: 'Profile', id: 'profile', disabled: false},
  {text: 'Log Out', id: 'log_out'}
];


export const NavBar = ({user}) => {
  const [basket, ] = useContext(BasketContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(navBarReducer, defaultState);

  function navBarReducer(state, action) {
    switch (action.type) {
      case 'quantity_update':
        return {
          ...state,
          quantity: action.quantity
        };
      case 'dropdown_items_update':
        return {
          ...state,
          dropdownItems: [
            ...(action.user.permission_group === 'admin' ? [{ text: 'Manage', id: 'manage' }] : []),
            ...defaultDropdownItems
          ]
        };
      default:
        break;
    }
  }

  function onItemClick(event) {
    if (event.detail.id === 'log_out') {
      Auth.signOut();
    } else if (event.detail.id === 'profile') {
      history.push('/profile');
    } else if (event.detail.id === 'manage') {
      history.push('/manage');
    }
  }

  useEffect(() => {
    dispatch({
      type: 'quantity_update',
      quantity: basket === undefined ? 0 : basket.items.reduce((a, b) => {return a + b.quantity;}, 0)
    });
    dispatch({
      type: 'dropdown_items_update',
      user: user
    });
  }, [basket, user]);

  return (
    <div id="navbar" className="menu-list">
      <Grid
        gridDefinition={[
          {colspan: 1, offset: {xxs: 0}},
          {colspan: 1, offset: {xxs: 9}},
          {colspan: 1, offset: {xxs: 0}},
        ]}
      >
        <div><NavLink className='title' to="/">Dorea</NavLink></div>
        <ShoppingBasket quantity={state.quantity}/>
        <div>
          <ButtonDropdown
            items={state.dropdownItems}
            onItemClick={onItemClick}
          >
            Profile
          </ButtonDropdown>
        </div>
      </Grid>
    </div>
  );
};
