import React, {useEffect, useReducer, Fragment} from 'react';
import {Header, Table, Box, Badge, Icon, Grid} from '@amzn/awsui-components-react/polaris';
import {Button} from '@amzn/awsui-components-react/polaris';
import {Link} from 'react-router-dom';
import {CharityAPI} from '../api/charity';


export const CharityListPage = () => {

  const defaultState = {
    isFetching: true,
    charities: []
  };

  function charityListReducer(state, action) {
    switch (action.type) {
      case 'fetch_charities_success':
        return {
          ...state,
          isFetching: false,
          charities: action.charities
        };
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [state, dispatch] = useReducer(charityListReducer, defaultState);

  function fetchCharities() {
    CharityAPI.getCharities((response) => dispatch({
      type: 'fetch_charities_success',
      charities: response
    }));
  }

  useEffect(() => {
    fetchCharities();
  }, []);

  return (
    <Fragment>
      <Table
        columnDefinitions={[
          {
            id: 'contact_name',
            header: 'Contact name',
            cell: e => e.first_name + ' ' + e.last_name,
            width: 150,
            minWidth: 140
          },
          {
            id: 'vendor_code',
            header: 'Vendor Code',
            cell: e => e.vendor_code,
            width: 150,
            minWidth: 140
          },
          {
            id: 'username',
            header: 'Username',
            cell: e => e.username,
            width: 150,
            minWidth: 140
          },
          {
            id: 'email',
            header: 'Email',
            cell: e => e.email,
            width: 250,
            minWidth: 200
          },
          {
            id: 'is_active',
            header: 'Active',
            cell: e => (e.is_active ?
              <Icon
                name="treeview-expand"
                size="normal"
                variant="success"
              />
              :
              <Icon
                name="treeview-collapse"
                size="normal"
                variant="error"
              />),
            width: 150,
            minWidth: 140
          },
          {
            id: 'groups',
            header: 'Groups',
            cell: e => e.business_groups.map((business_group) => (
              <Badge key={business_group.type + ': ' + business_group.value} color='grey'>{business_group.type + ': ' + business_group.value}</Badge>)),
            minWidth: 150
          },
          {
            id: 'actions',
            header: 'Actions',
            cell: e => <Link to={`/manage/charity/${e.username}`} className='actions-list-icon-link'><Icon name='edit'/></Link>,
            width: 150,
            minWidth: 140
          }
        ]}
        items={state.charities}
        loading={state.isFetching}
        loadingText='Loading charities'
        resizableColumns
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No charities</b>
            <Box
              padding={{bottom: 's'}}
              variant='p'
              color='inherit'
            >
              No charities to display.
            </Box>
          </Box>
        }
        header={
          <Grid
            gridDefinition={[
              {colspan: 10},
              {colspan: 2}
            ]}
          >
            <div><Header>Charities</Header></div>
            <div className='right-text'><Link to='/manage/charity/new'><Button variant='primary'>Onboard a new charity</Button></Link></div>
          </Grid>
        }
      />
    </Fragment>);
};