import React from 'react';

export const BasketDescription = (basket, isCharity) => {

  const totalUnits = basket.items.reduce(function (cur, next) {return cur + next.quantity;}, 0);
  const totalWeight = (Math.round(100 * basket.items.reduce(function (cur, next) {return cur + next.item.weight_kg * next.quantity ?? 0;}, 0)) / 100).toFixed(2);
  // const totalCogs = (Math.round(100 * basket.items.reduce(function (cur, next) {return cur + next.quantity * parseFloat(next.item.cogs_per_unit) || 0.0;}, 0.0)) / 100).toFixed(2);


  if (basket.items !== undefined && totalUnits > 0) {
    return (<div>
      You have chosen and submitted:
      <ul>
        <li>{basket.items.length} ASINs</li>
        <li>{totalUnits} units</li>
        <li>{totalWeight} kg</li>
      </ul>
    </div>);
  }
};