import React, {useEffect, useReducer} from 'react';
import {
  Header,
  Container,
  Button,
  Input,
  Form,
  FormField,
  SpaceBetween,
  RadioGroup, Multiselect, ColumnLayout
} from '@amzn/awsui-components-react';
import {RestAPI as API} from '@aws-amplify/api-rest';

const defaultState = {
  emailField: '',
  usernameField: '',
  vendorCodeField: '',
  firstNameField: '',
  lastNameField: '',
  businessGroups: [],
  businessGroupsAvailable: [],
  unitsLimit: null,
  cogsLimit: null
};

export const UserForm = (
  {formFields, onSubmitHandler, isSaving, userFormVisibility}
) => {
  function userFormReducer(state, action) {
    switch (action.type) {
      case 'fetch_available_business_groups_success':
        return {
          ...state,
          businessGroupsAvailable: transformBusinessGroupsResponse(action.businessGroupsAvailable),
        };
      case 'set_form_fields':
        return {
          ...state,
          firstNameField: action.user_fields.firstNameField,
          lastNameField: action.user_fields.lastNameField,
          emailField: action.user_fields.emailField,
          usernameField: action.user_fields.usernameField,
          vendorCodeField: action.user_fields.vendorCodeField,
          isActive: action.user_fields.isActive,
          businessGroups: transformBusinessGroupsResponse(action.user_fields.businessGroups),
          unitsLimit: action.user_fields.unitsLimitField,
          cogsLimit: action.user_fields.cogsLimitField
        };
      case 'set_first_name_input':
        return {
          ...state,
          firstNameField: action.value
        };
      case 'set_last_name_input':
        return {
          ...state,
          lastNameField: action.value
        };
      case 'set_email_input':
        return {
          ...state,
          emailField: action.value
        };
      case 'set_username_input':
        return {
          ...state,
          usernameField: action.value
        };
      case 'set_vendor_code_input':
        return {
          ...state,
          vendorCodeField: action.value
        };
      case 'set_is_active_radio':
        return {
          ...state,
          isActive: action.value
        };
      case 'set_units_limit':
        return {
          ...state,
          unitsLimit: action.value
        };
      case 'set_cogs_limit':
        return {
          ...state,
          cogsLimit: action.value
        };
      case 'set_business_groups_multiselect':
        return {
          ...state,
          businessGroups: action.value
        };
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [state, dispatch] = useReducer(userFormReducer, defaultState);

  useEffect(() => {
    dispatch({
      type: 'set_form_fields',
      user_fields: formFields
    });
  }, [formFields, userFormVisibility]);

  useEffect(() => {
    API.get('API', '/charity/business_groups')
      .then(response => {
        dispatch({
          type: 'fetch_available_business_groups_success',
          businessGroupsAvailable: response
        });
      })
      .catch(error => console.error(error));
  }, []);

  function onFirstNameChange(event) {
    dispatch({
      type: 'set_first_name_input',
      value: event.detail.value
    });
  }

  function onLastNameChange(event) {
    dispatch({
      type: 'set_last_name_input',
      value: event.detail.value
    });
  }

  function onEmailChange(event) {
    dispatch({
      type: 'set_email_input',
      value: event.detail.value
    });
  }

  function onUsernameChange(event) {
    dispatch({
      type: 'set_username_input',
      value: event.detail.value
    });
  }

  function onVendorCodeChange(event) {
    dispatch({
      type: 'set_vendor_code_input',
      value: event.detail.value
    });
  }

  function onIsActiveChange(event) {
    dispatch({
      type: 'set_is_active_radio',
      value: event.detail.value
    });
  }

  function onUnitsLimitChange(event) {
    if (!new RegExp(/(^\d*$|^$)/).test(event.detail.value)) {
      return;
    }
    dispatch({
      type: 'set_units_limit',
      value: event.detail.value
    });
  }

  function onCogsLimitChange(event) {
    if (!new RegExp(/(^[0-9]*(\.[0-9]{0,2})$|^$|^\d*$)/).test(event.detail.value)) {
      return;
    }
    dispatch({
      type: 'set_cogs_limit',
      value: event.detail.value
    });
  }

  function onBusinessGroupsChange(event) {
    dispatch({
      type: 'set_business_groups_multiselect',
      value: event.detail.selectedOptions
    });
  }

  function transformBusinessGroupsResponse(response) {
    var businessGroups = response;
    if (!Array.isArray(businessGroups)) {
      businessGroups = Object.keys(businessGroups).flatMap((key) => (businessGroups[key].map(
        (value) => ({
          type: key,
          value: value
        })
      )));
    }

    return businessGroups.map(businessGroup => ({
      label: businessGroup.value,
      value: businessGroup.type + ': ' + businessGroup.value,
      description: businessGroup.type,
      typeSubmit: businessGroup.type,
      valueSubmit: businessGroup.value
    }));
  }

  function handleReset() {
    dispatch({
      type: 'set_form_fields',
      user_fields: {
        firstNameField: '',
        lastNameField: '',
        emailField: '',
        usernameField: '',
        vendorCodeField: '',
        isActive: state.isActive,
        businessGroups: [],
        unitsLimitField: null,
        cogsLimitField: null
      }
    });
  }

  function handleSubmit() {
    formFields = {
      firstNameField: state.firstNameField,
      lastNameField: state.lastNameField,
      emailField: state.emailField,
      usernameField: state.usernameField,
      vendorCodeField: state.vendorCodeField,
      isActive: state.isActive,
      businessGroups: state.businessGroups,
      unitsLimitField: state.unitsLimit,
      cogsLimitField: state.cogsLimit
    };
    onSubmitHandler(formFields);
  }

  return (
    <Container
      header={
        <Header
          variant="h3"
          description="You can edit and save your preferences"
        >
          Profile
        </Header>
      }
    >
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {userFormVisibility.isResetVisible && (
              <Button variant="normal" onClick={handleReset}>Reset</Button>
            )}
            <Button variant="primary" onClick={handleSubmit} loading={isSaving}>Save</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Given Name">
            <Input value={state.firstNameField} onChange={onFirstNameChange}/>
          </FormField>
          <FormField label="Family Name">
            <Input value={state.lastNameField} onChange={onLastNameChange}/>
          </FormField>
          <FormField label="Email">
            <Input value={state.emailField} onChange={onEmailChange}/>
          </FormField>
          <FormField label="Username">
            <Input value={state.usernameField} onChange={onUsernameChange} disabled={userFormVisibility.isUsernameFieldDisabled}/>
          </FormField>
          <FormField label="Vendor Code" constraintText="5 characters minimum">
            <Input value={state.vendorCodeField} onChange={onVendorCodeChange} disabled={userFormVisibility.isVendorCodeDisabled}/>
          </FormField>
          <FormField
            stretch
            description="Empty the field to remove the limit"
            label="Quantity limits"
          >
            <ColumnLayout columns={3}>
              <Input value={state.unitsLimit} onChange={onUnitsLimitChange} placeholder={'Units'} constraintText={'Integers only'} disabled={userFormVisibility.areQuantityLimitsDisabled}/>
              <Input value={state.cogsLimit} onChange={onCogsLimitChange} placeholder={'COGS (Value with max 2 decimal places)'} disabled={userFormVisibility.areQuantityLimitsDisabled}/>
            </ColumnLayout>
          </FormField>
          { userFormVisibility.isStatusVisible ? (
            <FormField label="Status">
              <RadioGroup
                ariaLabel={'Status'}
                onChange={onIsActiveChange}
                value={state.isActive}
                items={[
                  {
                    value: true,
                    label: 'Active',
                    description: 'Charity will be able to sign in with the provided password sent by email',
                  },
                  {
                    value: false,
                    label: 'Inactive',
                    description: 'Charity account will need to be activated later',
                  }
                ]}
              />
            </FormField>
          ) : null }
          <FormField label="Groups" description='Groups will restrict access to charities'>
            <Multiselect
              disabled={userFormVisibility.areGroupsDisabled}
              selectedOptions={state.businessGroups}
              onChange={onBusinessGroupsChange}
              deselectAriaLabel={e => 'Remove ' + e.label}
              options={state.businessGroupsAvailable}
              placeholder="Choose groups"
              selectedAriaLabel="Selected"
              filteringType="auto"
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Container>
  );
};
