import React from 'react';
import {Grid, Header, Tabs} from '@amzn/awsui-components-react';
import {FAQ} from './FAQ';
import {SOP} from './SOP';

export const HelpPage = () => {
  return (
    <Grid
      gridDefinition={[
        { colspan: 8, offset: { xxs: 2 } },
      ]}
    >
      <div>
        <Header variant={'h1'}>Help</Header>
        <Tabs
          tabs={[
            {
              label: 'How to (English)',
              id: 'sop_english',
              content: <SOP language={'en'}/>
            },
            {
              label: 'How to (German)',
              id: 'sop_german',
              content: <SOP language={'de'}/>
            },
            {
              label: 'FAQ (English)',
              id: 'faq_english',
              content: <FAQ language={'en'}/>
            },
            {
              label: 'FAQ (German)',
              id: 'faq_german',
              content: <FAQ language={'de'}/>
            }
          ]}
        />
      </div>
    </Grid>
  );
};