import React from 'react';

export const TableItemImage = (item) => {
  return <div className={'center-text'}>
    <img
      src={item.image_url}
      alt=""
      className="fit"
    />
    <span>{item.asin}</span>
  </div>;
};