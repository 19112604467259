import React, {Fragment, useEffect, useState} from 'react';
import {Filter} from './Filter';
import {TextFilter} from '@amzn/awsui-components-react/polaris';

export const FilterSection = ({values, onChange}) => {
  const [checkboxes, setCheckboxes] = useState();
  const [filteringText, setFilteringText] = useState('');

  useEffect(() => {
    let disabled = values.length <= 1;
    setCheckboxes(values.filter(value => {return value.includes(filteringText);}).map(
      value => <Filter
        key={value}
        label={value}
        isSelected={disabled || false}
        onChange={(label, isChecked) => {onChange(label, isChecked);}}
      />));
  }, [values, onChange, filteringText]);


  return (
    <div>
      <Fragment>
        <TextFilter
          filteringText={filteringText}
          filteringPlaceholder="Filter"
          filteringAriaLabel="Filter"
          onChange={({ detail }) =>
            setFilteringText(detail.filteringText)
          }
        />

      </Fragment>
      <Fragment>
        {checkboxes}
      </Fragment>
    </div>
  );
};