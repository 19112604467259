import React, {useContext, useEffect, useReducer, Fragment} from 'react';
import {
  Box, Container, Flashbar,
  Grid, Header,
  Pagination, Select, Spinner, Table, TextFilter
} from '@amzn/awsui-components-react/polaris';
import {BusinessGroupSection} from './BusinessGroupSection';
import {BasketContext} from '../contexts/basket';
import {FilterSection} from './FilterSection';
import {BasketAPI} from '../api/basket';
import {UserAPI} from '../api/user';
import {ItemsAPI} from '../api/items';
import {TableItemImage} from '../partials/Item/TableItemImage';
import {TableItemDescription} from '../partials/Item/TableItemDescription';
import {TableItemQuantity} from '../partials/Item/TableItemQuantity';
import {TableItemAmazonLink} from '../partials/Item/TableItemAmazonLink';
import {InformationHeader} from '../partials/Item/InformationHeader';
import {TableHeader} from '../partials/Item/TableHeader';


export const ItemsListPage = ({user}) => {

  const defaultState = {
    isFetchingUser: true,
    isFetchingFilters: true,
    user: null,
    businessGroups: [],
    businessGroupsChecked: [],
    businessGroupsRendered: null,
    isFetchingItems: true,
    items: [],
    itemsOriginalQuantity: {},
    needsRefresh: false,
    currentPageIndex: 1,
    itemsCount: 0,
    unitsCount: 0,
    pagesCount: 1,
    filteringText: '',
    businessGroupsCheckedLength: 0,
    productGroupsSelectedLength: 0,
    itemsLoading: [],
    itemsDisabled: [],
    productGroups: null,
    productGroupsSelected: [],
    saveButtonsEnabled: new Set(),
    feedbackDate: null,
    flashItems: [],
    beforeSavingItems: []
  };

  const groupBy = (items) => items.reduce(function (r, a) {
    r[a.type] = r[a.type] || [];
    r[a.type].push(a.value);
    return r;
  }, Object.create(null));

  const [state, dispatch] = useReducer(itemsListReducer, defaultState);
  const [, setBasket] = useContext(BasketContext);

  function itemsListReducer(state, action) {
    switch (action.type) {
      case 'fetch_profile_request':
        return {
          ...state,
          isFetchingUser: true
        };
      case 'fetch_profile_success':
        var businessGroups = groupBy(action.user.business_groups);
        return {
          ...state,
          isFetchingUser: false,
          businessGroups: businessGroups,
          businessGroupsChecked: businessGroups,
          businessGroupsRendered: Object.keys(businessGroups).map(
            key => <BusinessGroupSection
              key={key}
              header={key}
              values={businessGroups[key]}
              onChange={onChangeBusinessGroup}/>
          )
        };
      case 'fetch_filters_request':
        return {
          ...state,
          isFetchingFilters: true
        };
      case 'fetch_filters_success':
        return {
          ...state,
          isFetchingFilters: false,
          productGroups: action.response.product_groups,
          productGroupsSelected: []
        };
      case 'fetch_items_request':
        return {
          ...state,
          isFetchingItems: true
        };
      case 'fetch_items_success':
        return {
          ...state,
          isFetchingItems: false,
          items: action.items,
          itemsOriginalQuantity: action.items.reduce(function(acc, cur, i) {
            acc[cur.id] = cur.units_chosen;
            return acc;
          }, {}),
          itemsCount: action.itemsCount,
          unitsCount: action.unitsCount,
          pagesCount: Math.ceil(action.itemsCount / 100),
          feedbackDate: action.feedbackDate
        };
      case 'empty_items':
        return {
          ...state,
          items: [],
          itemsOriginalQuantity: {},
          itemsCount: 0,
          pagesCount: 1
        };
      case 'page_index_change_request':
        return {
          ...state,
          currentPageIndex: action.newPageIndex
        };
      case 'filtering_text_change':
        return {
          ...state,
          filteringText: action.filteringText
        };
      case 'business_groups_checked_change':
        if (!action.isChecked) {
          let businessGroupsChecked = removeValueInBusinessGroups(action.label, action.value, state.businessGroupsChecked);
          return {
            ...state,
            businessGroupsChecked: businessGroupsChecked,
            businessGroupsCheckedLength: [].concat.apply([], Object.values(businessGroupsChecked)).length,
          };
        } else {
          let businessGroupsChecked = addValueInBusinessGroups(action.label, action.value, state.businessGroupsChecked);
          return {
            ...state,
            businessGroupsChecked: businessGroupsChecked,
            businessGroupsCheckedLength: [].concat.apply([], Object.values(businessGroupsChecked)).length,
          };
        }
      case 'product_group_filter_change':
        if (!action.isChecked) {
          return {
            ...state,
            productGroupsSelected: state.productGroupsSelected.filter(function(value, index, arr){
              return value !== action.productGroup;
            })
          };
        } else {
          return {
            ...state,
            productGroupsSelected: state.productGroupsSelected.concat([action.productGroup])
          };
        }
      case 'update_units_chosen_request':
        return {
          ...state,
          beforeSavingItems: state.items,
          items: state.items.map(item => {
            if (item.id === action.id) {
              item.units_chosen = action.value;
            }
            if (item.units_chosen > item.units_available) {
              item.is_overstock = true;
            }
            return item;
          }),
          itemsLoading: [action.id]
        };
      case 'update_basket_request':
        return {
          ...state,
          itemsLoading: [action.itemId]
        };
      case 'update_basket_success':
        return {
          ...state,
          isFetchingItems: false,
          items: state.items.map((oldItem) => {
            if (oldItem.id === action.item.id) {
              return action.item;
            } else {
              return oldItem;
            }
          }),
          itemsOriginalQuantity: state.items.reduce(function(acc, cur, i) {
            acc[cur.id] = cur.units_chosen;
            return acc;
          }, {}),
          itemsLoading: []
        };
      case 'update_basket_error':
        return {
          ...state,
          isFetchingItems: false,
          items: state.beforeSavingItems,
          itemsOriginalQuantity: state.items.reduce(function(acc, cur, i) {
            acc[cur.id] = cur.units_chosen;
            return acc;
          }, {}),
          itemsLoading: [],
          flashItems: [{
            type: 'error',
            content: action.errorMessage,
            dismissible: true,
            id: 'update-basket-error',
            onDismiss: () => {
              dispatch({
                type: 'dismiss_flash'
              });
            }
          }]
        };
      case 'update_basket_bulk_request':
        return {
          ...state,
          itemsLoading: state.items.map(item => item.id)
        };
      case 'update_basket_bulk_success':
        return {
          ...state,
          itemsLoading: [],
        };
      case 'dismiss_flash':
        return {...state, flashItems: []};
      default:
        throw new Error('Unknown action type.');
    }
  }

  function onPageChange(newPageIndex) {
    dispatch({
      type: 'page_index_change_request',
      newPageIndex: newPageIndex
    });
    window.scrollTo(0, 0);
  }

  function onChangeFilteringText(filteringText) {
    dispatch({
      type: 'filtering_text_change',
      filteringText: filteringText
    });
  }

  function onChangeBusinessGroup(label, value, isChecked) {
    dispatch({
      type: 'business_groups_checked_change',
      label: label,
      value: value,
      isChecked: isChecked
    });
  }

  function onChangeProductGroupFilter(productGroup, isChecked) {
    dispatch({
      type: 'product_group_filter_change',
      productGroup: productGroup,
      isChecked: isChecked
    });
  }

  function removeValueInBusinessGroups(label, value, businessGroups) {
    if (businessGroups[label] !== undefined) {
      for (var i = 0; i < businessGroups[label].length; i++) {
        if (businessGroups[label][i] === value) {
          businessGroups[label].splice(i, 1);
          return businessGroups;
        }
      }
    }
    return businessGroups;
  }

  function addValueInBusinessGroups(label, value, businessGroups) {
    if (businessGroups[label] !== undefined) {
      for (var i = 0; i < businessGroups[label].length; i++) {
        if (businessGroups[label][i] === value) {
          return businessGroups;
        }
      }
      businessGroups[label].push(value);
    }
    return businessGroups;
  }

  function onQuantityDesiredChange(id, detail) {
    let quantityDesired = parseInt(detail.selectedOption.value);
    dispatch({
      type: 'update_units_chosen_request',
      id: id,
      value: quantityDesired
    });
    BasketAPI.updateItemQuantity(id, quantityDesired, updateBasketCallback, updateBasketCallbackError);
  }

  function updateBasketCallback(response) {
    dispatch({
      type: 'update_basket_success',
      item: response.item,
      basket: response.basket
    });
    setBasket({items: response.basket});
  }

  function updateBasketCallbackError(error) {
    dispatch({
      type: 'update_basket_error',
      errorMessage: error.response.data[0]
    });
  }

  function updateBasketBulkCallback(response) {
    dispatch({
      type: 'update_basket_bulk_success',
      basket: response.basket
    });
    setBasket({items: response.basket});
    fetchItems();
  }

  function fetchAvailableBusinessGroups() {
    dispatch({
      type: 'fetch_profile_request'
    });
    UserAPI.getBusinessGroups((response) => {
      dispatch({
        type: 'fetch_profile_success',
        user: response
      });
    });
  }

  function fetchAvailableFilters() {
    dispatch({
      type: 'fetch_filters_request'
    });
    ItemsAPI.getAvailableFilters(state, (response) => dispatch({
      type: 'fetch_filters_success',
      response: response
    }));
  }

  function onClickBulkAddItems() {
    dispatch({
      type: 'update_basket_bulk_request'
    });
    ItemsAPI.bulkAddItems(state, updateBasketBulkCallback);
  }

  function fetchItems() {
    dispatch({
      type: 'fetch_items_request'
    });
    ItemsAPI.getItems(state, (response) => dispatch({
      type: 'fetch_items_success',
      items: response.results,
      itemsCount: response.count,
      unitsCount: response.units_count,
      feedbackDate: response.feedback_date
    }));
  }

  useEffect(() => {
    if (state.businessGroupsChecked.length === 0) {
      return;
    }
    if (!('FC' in state.businessGroupsChecked)
      || state.businessGroupsChecked['FC'].length === 0
    ) {
      dispatch({
        type: 'empty_items'
      });
      return;
    }
    fetchItems();
  }, // eslint-disable-next-line
  [
    state.currentPageIndex,
    state.filteringText,
    state.businessGroupsChecked,
    state.businessGroupsCheckedLength,
    state.productGroupsSelected.length
  ]);

  useEffect(() => {
    fetchAvailableBusinessGroups();
  }, []);

  useEffect(() => {
    fetchAvailableFilters();
  }, // eslint-disable-next-line
    [
    state.businessGroupsChecked,
    state.businessGroupsCheckedLength
  ]);

  return (
    <Grid
      gridDefinition={[{colspan: 2}, {colspan: 10}]}
    >
      <div>
        <Fragment>
          <Container
            header={
              <Header>
                Business Groups
              </Header>
            }
          >
            {(state.businessGroupsRendered !== null) ? state.businessGroupsRendered : <Spinner/>}
          </Container>
        </Fragment>
        <Fragment>
          {(state.productGroups !== null && !state.isFetchingFilters) ? <Container
            header={<Header variant='h2'>{state.productGroups.label}</Header>}>
            <FilterSection values={state.productGroups.values} onChange={onChangeProductGroupFilter}/>
          </Container> : <Spinner/>}
        </Fragment>
      </div>
      <div>
        <InformationHeader feedbackDate={state.feedbackDate}/>
        <Flashbar items={state.flashItems}/>
        <Table
          className={'items-table'}
          items={state.items}
          columnDefinitions={[
            {
              id: 'image',
              header: '',
              cell: e => TableItemImage(e)
            },
            {
              id: 'name',
              header: 'Name',
              cell: e => TableItemDescription(e),
            },
            {
              id: 'warehouse',
              header: 'Warehouse',
              cell: e => e.warehouse
            },
            {
              id: 'available_quantity',
              header: 'Quantity available',
              cell: e => TableItemQuantity(e.units_available + state.itemsOriginalQuantity[e.id] ?? 0, e.weight_kg),
              width: 200
            },
            {
              id: 'number_of_items',
              header: 'Items per pack',
              cell: e => e.number_of_items,
              width: 150
            },
            {
              id: 'actions',
              header: 'Quantity desired',
              cell: e => <Grid
                gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
              >
                <div className='center-text'>
                  <Select
                    className='quantity-select'
                    disabled={e.is_removed}
                    selectedOption={{label: e.units_chosen + (e.number_of_items > 1 ? ' (x' + e.number_of_items + ')': ''), value: e.units_chosen}}
                    onChange={({detail}) => onQuantityDesiredChange(e.id, detail)}
                    options={
                      [...Array(e.units_available + 1 + state.itemsOriginalQuantity[e.id] ?? 0).keys()].map(i => {
                        return {label: String(i) + (e.number_of_items > 1 ? ' (x' + e.number_of_items + ')' : ''), value: i};
                      }).filter(function (option) {
                        if((e.units_available + state.itemsOriginalQuantity[e.id]) === option.value) {
                          return true;
                        }
                        return (e.units_available + 1 + state.itemsOriginalQuantity[e.id] ?? 0) > 100 ? option.value % 10 === 0 : true;
                      })
                    }
                    expandToViewport
                  /></div>
                <div className={state.itemsLoading.find(v => v === e.id) !== undefined ? 'spinner-visible': 'spinner-hidden'}>
                  <Spinner/>
                </div>
              </Grid>,
              width: 200
            },
            {
              id: 'on_amazon',
              header: 'See on Amazon',
              cell: e => TableItemAmazonLink(e),
              width: 100
            }
          ]}
          wrapLines
          loading={state.isFetchingItems}
          loadingText='Loading items'
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No items</b>
              <Box
                padding={{bottom: 's'}}
                variant='p'
                color='inherit'
              >
                No items available to display.
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              filteringPlaceholder='Find items'
              filteringText={state.filteringText}
              onChange={({detail}) => onChangeFilteringText(detail.filteringText)}
            />
          }
          header=<TableHeader user={user} onClickBulkAddItems={onClickBulkAddItems} itemsCount={state.itemsCount} unitsCount={state.unitsCount}/>
          pagination={
            <Pagination
              currentPageIndex={state.currentPageIndex}
              pagesCount={state.pagesCount}
              onChange={({detail}) => onPageChange(detail.currentPageIndex)}
            />
          }
          footer={
            <Pagination
              currentPageIndex={state.currentPageIndex}
              pagesCount={state.pagesCount}
              onChange={({detail}) => onPageChange(detail.currentPageIndex)}
            />
          }
        />
      </div>
    </Grid>
  );
};