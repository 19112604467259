import React, {useReducer} from 'react';
import {useHistory} from 'react-router-dom';
import {UserForm} from '../partials/profile/UserForm';
import {CharityAPI} from '../api/charity';
import {UserFormVisibility} from '../partials/profile/UserFormVisibility';

export const CharityNewPage = () => {
  const history = useHistory();

  const defaultState = {
    isSaving: false,
    charity: null
  };

  function charityNewReducer(state, action) {
    switch (action.type) {
      case 'save_charity_request':
        return {
          ...state,
          isSaving: true
        };
      case 'save_charity_success':
        history.push('/manage');
        return {
          ...state,
          isSaving: false,
          charity: action.charity
        };
      case 'save_charity_error':
        return {
          ...state,
          isSaving: false
        };
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [state, dispatch] = useReducer(charityNewReducer, defaultState);

  function handleSubmit(formFields) {
    dispatch({
      type: 'save_charity_request'
    });
    CharityAPI.createCharity(formFields, (response) => dispatch({
      type: 'save_charity_success',
      charity: response
    }), (error) => {
      dispatch({
        type: 'save_charity_error'
      });
    });
  }

  return (
    <div>
      <UserForm
        formFields={{
          firstNameField: '',
          lastNameField: '',
          emailField: '',
          usernameField: '',
          vendorCodeField: '',
          isActive: true,
          businessGroups: []
        }}
        onSubmitHandler={handleSubmit}
        isSaving={state.isSaving}
        userFormVisibility={new UserFormVisibility(false, true, true, false, false, false)}
      />
    </div>
  );
};