import React, {useEffect, useState} from 'react';
import {BusinessGroupValue} from './BusinessGroupValue';

export const BusinessGroupSection = ({header, values, onChange}) => {
  const [checkboxes, setCheckboxes] = useState();

  useEffect(() => {
    let disabled = values.length <= 1;
    setCheckboxes(values.map(
      value => <BusinessGroupValue
        key={header + ':' + value}
        label={value}
        isSelected={disabled || true}
        onChange={(label, isChecked) => {onChange(header, label, isChecked);}}
        disabled={disabled}
      />));
  }, [values, header, onChange]);


  return (
    <div>
      <h3>{header}</h3>
      <div>
        {checkboxes}
      </div>
    </div>
  );
};