import React from 'react';
import faqEnglish from './faq_english.json';
import faqGerman from './faq_german.json';
import {ExpandableSection, Header} from '@amzn/awsui-components-react';

export const FAQ = ({language}) => {

  const mapping = {
    'en': faqEnglish,
    'de': faqGerman
  };
  const faqContent = mapping[language] ?? faqEnglish;

  return (
    <div>
      <div className={'center-text'}>
        <img src={faqContent.header_image} alt={'header'}/>
      </div>
      <Header variant={'h2'}>{faqContent.header_title}</Header>
      <ul>
        {
          faqContent.faq_list.map(faqItem => {
            return (
              <ExpandableSection header={faqItem.title}>
                <b>Answer:</b> {faqItem.answer}
                <div>
                  {
                    faqItem.bullet_points !== undefined
                      ? (<ul>
                        {faqItem.bullet_points.map(bulletPoint => <li>{bulletPoint}</li>)}
                      </ul>)
                      : (<span/>)
                  }
                </div>
              </ExpandableSection>
            );
          })
        }
      </ul>
    </div>
  );
};