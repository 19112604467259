import React, {useEffect, useReducer, Fragment} from 'react';
import {Header, Table, Box, Icon, Grid, Input, Flashbar} from '@amzn/awsui-components-react/polaris';
import {Button} from '@amzn/awsui-components-react/polaris';
import {ItemsAPI} from '../api/items';
import {ASINModal} from '../partials/manage/ASINModal';
import {S3FileChoiceModal} from '../partials/manage/S3FileChoiceModal';


export const ItemsManagePage = () => {

  const defaultState = {
    isFetching: true,
    itemsStatus: {},
    needsRefresh: false,
    expirationDates: {},
    ASINModalProps: {visible: false, isLoading: false},
    S3FileChoiceModalProps: {visible: false, statusType: '', isUploading: false},
    flashbarItems: []
  };

  function itemsManageReducer(state, action) {
    switch (action.type) {
      case 'fetch_items_status_success':
        return {
          ...state,
          isFetching: false,
          itemsStatus: action.items_status,
          expirationDates: action.items_status['WEEKLY_AUTOMATED'].reduce((obj, cur, i) => {return { ...obj, [cur.snapshot_date + '-' + cur.country_code]: new Date(cur.expiration_date).toISOString().slice(0, 10) };}, {}),
          needsRefresh: false
        };
      case 'set_active_request':
        return {
          ...state,
          isFetching: true
        };
      case 'set_active_success':
        return {
          ...state,
          needsRefresh: true
        };
      case 'expiration_date_change_request':
        return {
          ...state,
          expirationDates: {...state.expirationDates, [action.key]: action.value}
        };
      case 'save_expiration_date_request':
        return {...state, isFetching: true};
      case 'save_expiration_date_success':
        return {...state, isFetching: false};
      case 'export_request':
        return {
          ...state,
          isFetching: true
        };
      case 'export_success':
        return {
          ...state,
          isFetching: false
        };
      case 'export_error':
        return {
          ...state,
          isFetching: false
        };
      case 'asin_modal_show':
        return {
          ...state,
          ASINModalProps: {
            visible: true,
            snapshotDate: action.snapshotDate,
            countryCode: action.countryCode
          }
        };
      case 'asin_modal_dismiss':
        return {
          ...state,
          ASINModalProps: {
            visible: false,
            isLoading: false
          }
        };
      case 'remove_items_request' || 'recover_items_request':
        return {
          ...state,
          ASINModalProps: {
            visible: true,
            isLoading: true
          }
        };
      case 's3_file_choice_modal_show':
        return {
          ...state,
          S3FileChoiceModalProps: {
            visible: true,
            statusType: 'loading',
            isUploading: false
          }
        };
      case 's3_file_choice_modal_dismiss':
        return {
          ...state,
          S3FileChoiceModalProps: {
            visible: false,
            statusType: '',
            isUploading: false
          }
        };
      case 's3_file_choice_validate_request':
        return {
          ...state,
          S3FileChoiceModalProps: {
            visible: true,
            statusType: 'loading',
            isUploading: true
          }
        };
      case 's3_file_choice_validate_success':
        return {
          ...state,
          S3FileChoiceModalProps: {
            visible: false,
            statusType: '',
            isUploading: false
          },
          flashbarItems: [{
            type: 'success',
            content: action.response.items_created + ' items have been added, alongside the creation of ' + action.response.tags_created + ' tag(s)',
            dismissible: true,
            onDismiss: () => dispatch({type: 'flashbar_empty_request'})
          }],
          needsRefresh: true
        };
      case 'flashbar_empty_request':
        return {...state, flashbarItems: []};
      case 'empty_manual_upload_request':
        return {
          ...state,
          isFetching: true
        };
      case 'empty_manual_upload_success':
        return {
          ...state,
          isFetching: false,
          needsRefresh: true
        };
      case 'set_basket_expired_request':
        return {
          ...state,
          isFetching: true
        };
      case 'set_basket_expired_success':
        return {
          ...state,
          isFetching: false
        };
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [state, dispatch] = useReducer(itemsManageReducer, defaultState);

  function fetchItemsStatus() {
    ItemsAPI.getItemsStatus((response) => dispatch({
      type: 'fetch_items_status_success',
      items_status: response
    }));
  }

  function setActive(snapshotDate, countryCode, isActive) {
    dispatch({
      type: 'set_active_request'
    });
    ItemsAPI.setItemsActive(snapshotDate, countryCode, isActive, 'set_active', (response) => dispatch({
      type: 'set_active_success'
    }));
  }

  function setActiveManual(countryCode, isActive) {
    dispatch({
      type: 'set_active_request'
    });
    ItemsAPI.setItemsActive(null, countryCode, isActive, 'set_active_manual', (response) => dispatch({
      type: 'set_active_success'
    }));
  }

  function setBasketExpired(countryCode, isExpired) {
    dispatch({
      type: 'set_basket_expired_request'
    });
    ItemsAPI.setBasketExpired(countryCode, isExpired, (response) => dispatch({
      type: 'set_basket_expired_success'
    }));
  }

  function setExpirationDate(snapshotDate, countryCode, expirationDate) {
    dispatch({
      type: 'expiration_date_change_request',
      key: snapshotDate + '-' + countryCode,
      value: expirationDate
    });
  }

  function saveExpirationDate(snapshotDate, countryCode) {
    dispatch({
      type: 'save_expiration_date_request',
      key: snapshotDate + '-' + countryCode
    });
    ItemsAPI.setItemsExpirationDate(
      snapshotDate,
      countryCode,
      state.expirationDates[snapshotDate + '-' + countryCode],
      (response) => dispatch({
        type: 'save_expiration_date_success'
      }));
  }

  function onClickASINModalShow(snapshotDate, countryCode) {
    dispatch({
      type: 'asin_modal_show',
      snapshotDate: snapshotDate,
      countryCode: countryCode
    });
  }

  function onClickASINModalDismiss() {
    dispatch({
      type: 'asin_modal_dismiss'
    });
  }

  function removeASINs(ASINModalProps, ASINs) {
    dispatch({
      type: 'remove_items_request'
    });
    ItemsAPI.removeItems(ASINModalProps.snapshotDate, ASINModalProps.countryCode, ASINs, () => {
      dispatch({
        type: 'asin_modal_dismiss'
      });
    });
  }

  function recoverASINs(ASINModalProps, ASINs) {
    dispatch({
      type: 'remove_items_request'
    });
    ItemsAPI.recoverItems(ASINModalProps.snapshotDate, ASINModalProps.countryCode, ASINs, () => {
      dispatch({
        type: 'asin_modal_dismiss'
      });
    });
  }

  function onClickS3FileChoiceModalShow() {
    dispatch({
      type: 's3_file_choice_modal_show'
    });
  }

  function onClickS3FileChoiceModalDismiss() {
    dispatch({
      type: 's3_file_choice_modal_dismiss'
    });
  }

  function onClickS3FileChoiceModalValidate(filename) {
    dispatch({
      type: 's3_file_choice_validate_request'
    });
    ItemsAPI.uploadManualFile(filename, (response) => {
      dispatch({
        type: 's3_file_choice_validate_success',
        response: response
      });
    });
  }

  function onExportClick(snapshotDate, countryCode, id) {
    exportRequested(snapshotDate, countryCode, id);
  }

  function exportRequested(snapshotDate, countryCode, exportType) {
    dispatch({
      type: 'export_request'
    });
    ItemsAPI.exportBasket(snapshotDate, countryCode, exportType, (response) => {
      dispatch({
        type: 'export_success'
      });
    }, (error) => {
      dispatch({
        type: 'export_error'
      });
    });
  }

  function onEmptySelectionClickManual(countryCode) {
    dispatch({
      type: 'empty_manual_upload_request'
    });
    ItemsAPI.emptyManualUpload(countryCode, () => {
      dispatch({
        type: 'empty_manual_upload_success'
      });
    });
  }

  useEffect(() => {
    fetchItemsStatus();
  }, [state.needsRefresh]);

  return (
    <Fragment>
      <ASINModal ASINModalProps={state.ASINModalProps} onClickASINModalDismiss={onClickASINModalDismiss} removeASINs={removeASINs} recoverASINs={recoverASINs}/>
      <Table
        columnDefinitions={[
          {
            id: 'snapshot_date',
            header: 'Extract date',
            cell: e => new Date(Date.parse(e.snapshot_date)).toISOString().slice(0, 10),
            width: 180
          },
          {
            id: 'expiration_date',
            header: 'Expires at',
            cell: e => (
              <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                <Input
                  onChange={({ detail }) => setExpirationDate(e.snapshot_date, e.country_code, detail.value)}
                  value={state.expirationDates[e.snapshot_date + '-' + e.country_code]}
                />
                <Button iconName='upload' variant='icon' onClick={() => saveExpirationDate(e.snapshot_date, e.country_code)}/>
              </Grid>
            ),
            width: 250
          },
          {
            id: 'country_code',
            header: 'Country',
            cell: e => <span><img
              src={'/img/flags/' + e.country_code.toLowerCase() + '.svg'}
              alt={e.country_code} width={18}
            /> {e.country_code}</span>,
            width: 100
          },
          {
            id: 'asin_count',
            header: 'ASIN Count',
            cell: e => e.asin_count,
            width: 120
          },
          {
            id: 'is_active',
            header: 'Active',
            cell: e => (e.is_active ?
              <Icon
                name="treeview-expand"
                size="normal"
                variant="success"
              />
              :
              <Icon
                name="treeview-collapse"
                size="normal"
                variant="error"
              />),
            width: 100
          },
          {
            id: 'set_active_button',
            header: 'Actions',
            cell: e => {if (new Date(e.snapshot_date) < new Date() && new Date() < new Date(state.expirationDates[e.snapshot_date + '-' + e.country_code])) {
              return (
                <Grid
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <Button
                    onClick={event => setActive(e.snapshot_date, e.country_code, !e.is_active)}
                    variant={e.is_active ? 'normal' : 'primary'}
                  >{e.is_active ? 'Deactivate' : 'Activate'}</Button>
                  <Button iconName="edit" iconAlign="right" onClick={event => onClickASINModalShow(e.snapshot_date, e.country_code)}>ASINs</Button>
                </Grid>
              );
            } else {
              return (
                <Grid
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <span/>
                  <Button iconName="edit" iconAlign="right" onClick={event => onClickASINModalShow(e.snapshot_date, e.country_code)}>ASINs</Button>
                </Grid>
              );
            }
            },
            minWidth: 300
          },
          {
            id: 'export_buttons',
            header: 'Export',
            cell: e => <div>
              <Button onClick={event => onExportClick(e.snapshot_date, e.country_code, 'raw_selection')}>Selection</Button>
              <Button onClick={event => onExportClick(e.snapshot_date, e.country_code, 'basket')}>Removals</Button>
            </div>,
            width: 300
          }
        ]}
        items={state.itemsStatus['WEEKLY_AUTOMATED']}
        loading={state.isFetching}
        loadingText='Loading status'
        resizableColumns
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No extracts</b>
            <Box
              padding={{bottom: 's'}}
              variant='p'
              color='inherit'
            >
              No extracts to display from the last 30 days.
            </Box>
          </Box>
        }
        header={
          <Grid
            gridDefinition={[
              {colspan: 10},
              {colspan: 2}
            ]}
          >
            <div><Header>Weekly Automated Extracts</Header></div>
          </Grid>
        }
      />
      <Flashbar items={state.flashbarItems} />
      <S3FileChoiceModal
        S3FileChoiceModalProps={state.S3FileChoiceModalProps}
        onClickS3FileChoiceModalDismiss={onClickS3FileChoiceModalDismiss}
        onClickS3FileChoiceModalValidate={onClickS3FileChoiceModalValidate}
      />
      <Table
        columnDefinitions={[
          {
            id: 'country_code',
            header: 'Country',
            cell: e => <span><img
              src={'/img/flags/' + e.country_code.toLowerCase() + '.svg'}
              alt={e.country_code} width={18}
            /> {e.country_code}</span>,
            width: 100
          },
          {
            id: 'asin_count',
            header: 'ASIN Count',
            cell: e => e.asin_count,
            width: 120
          },
          {
            id: 'tags',
            header: 'Tags',
            cell: e => e.all_tags,
            width: 120
          },
          {
            id: 'is_active',
            header: 'Active',
            cell: e => (e.is_active ?
              <Icon
                name="treeview-expand"
                size="normal"
                variant="success"
              />
              :
              <Icon
                name="treeview-collapse"
                size="normal"
                variant="error"
              />),
            width: 100
          },
          {
            id: 'set_active_button',
            header: 'Actions',
            cell: e => {
              return (
                <Grid
                  gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                >
                  <Button
                    onClick={event => setActiveManual(e.country_code, !e.is_active)}
                    variant={e.is_active ? 'normal' : 'primary'}
                  >{e.is_active ? 'Deactivate' : 'Activate'}</Button>
                  <Button
                    onClick={event => setBasketExpired(e.country_code, true)}
                    variant='normal'
                  >Expire baskets</Button>
                </Grid>
              );
            },
            minWidth: 300
          },
          {
            id: 'export_buttons',
            header: 'Export',
            cell: e => <div>
              <Button onClick={event => onExportClick(null, e.country_code, 'raw_selection_manual')}>Selection</Button>
              <Button onClick={event => onExportClick(null, e.country_code, 'basket_manual')}>Removals</Button>
            </div>,
            width: 300
          },
          {
            id: 'empty_button',
            header: '',
            cell: e => <div>
              <Button onClick={event => onEmptySelectionClickManual(e.country_code)}>Empty selection</Button>
            </div>,
            width: 200
          }
        ]}
        items={state.itemsStatus['STATIC_MANUAL']}
        loading={state.isFetching}
        loadingText='Loading status'
        resizableColumns
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No extracts</b>
            <Box
              padding={{bottom: 's'}}
              variant='p'
              color='inherit'
            >
              No manual extracts to display.
            </Box>
          </Box>
        }
        header={
          <Grid
            gridDefinition={[
              {colspan: 10},
              {colspan: 2}
            ]}
          >
            <div><Header>Manual uploads</Header></div>
            <div className={'right-text'}>
              <Button onClick={onClickS3FileChoiceModalShow} variant={'primary'}>Upload</Button>
            </div>
          </Grid>
        }
      />
    </Fragment>
  );
};


