export class UserFormVisibility {

  constructor(
    isUsernameFieldDisabled,
    isResetVisible,
    isStatusVisible,
    isVendorCodeDisabled,
    areGroupsDisabled,
    areQuantityLimitsDisabled
  ) {
    this.isUsernameFieldDisabled = isUsernameFieldDisabled;
    this.isResetVisible = isResetVisible;
    this.isStatusVisible = isStatusVisible;
    this.isVendorCodeDisabled = isVendorCodeDisabled;
    this.areGroupsDisabled = areGroupsDisabled;
    this.areQuantityLimitsDisabled = areQuantityLimitsDisabled;
  }
}