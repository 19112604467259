import React, {Fragment, useEffect, useReducer} from 'react';
import {Spinner, Flashbar} from '@amzn/awsui-components-react';
import {UserForm} from '../partials/profile/UserForm';
import {UserAPI} from '../api/user';
import {UserFormVisibility} from '../partials/profile/UserFormVisibility';

const defaultState = {
  user: null,
  isFetching: true,
  isSaving: false,
  formFields: {},
  isActive: true,
  flashItems: []
};

export const ProfilePage = ({user}) => {
  const dismissableItems = {
    'success':
      [
        {
          type: 'success',
          content: 'Your profile has been sucessfully saved.',
          dismissible: true,
          id: 'save-success',
          onDismiss: () => {
            dispatch({
              type: 'dismiss_flash'
            });
          }
        }
      ]
  };

  function profileReducer(state, action) {
    switch(action.type) {
      case 'dismiss_flash':
        return {
          ...state,
          flashItems: []
        };
      case 'save_profile_request':
        return {
          ...state,
          isSaving: true
        };
      case 'save_profile_success':
        return {
          ...state,
          isSaving: false,
          user: action.user,
          isUsernameFieldDisabled: true,
          formFields: {
            emailField: action.user.email,
            usernameField: action.user.username,
            vendorCodeField: action.user.vendor_code,
            firstNameField: action.user.first_name,
            lastNameField: action.user.last_name,
            isActive: action.user.is_active,
            businessGroups: action.user.business_groups,
            unitsLimitField: action.user.units_limit,
            cogsLimitField: action.user.cogs_limit
          },
          flashItems: dismissableItems['success']
        };
      case 'fetch_profile_success':
        return {
          ...state,
          isFetching: false,
          user: action.user,
          isUsernameFieldDisabled: true,
          formFields: {
            emailField: action.user.email,
            usernameField: action.user.username,
            vendorCodeField: action.user.vendor_code,
            firstNameField: action.user.first_name,
            lastNameField: action.user.last_name,
            isActive: action.user.is_active,
            businessGroups: action.user.business_groups,
            unitsLimitField: action.user.units_limit,
            cogsLimitField: action.user.cogs_limit
          },
        };
      default:
        throw new Error('Unknown action type.');
    }
  }

  const [ state, dispatch ] = useReducer(profileReducer, defaultState);

  function fetchUser() {
    UserAPI.getUser((response) => dispatch({
      type: 'fetch_profile_success',
      user: response
    }));
  }

  useEffect(() => {
    fetchUser();
  }, []);

  function handleSubmit(formFields) {
    dispatch({type: 'save_profile_request'});
    UserAPI.updateUser(formFields, (response) => dispatch({
      type: 'save_profile_success',
      user: response
    }));
  }

  if (state.isFetching) {
    return (
      <div className='center-text'>
        <Spinner size='large'/>
      </div>
    );
  } else {
    return (
      <Fragment>
        <Flashbar items={state.flashItems}/>
        <UserForm
          formFields={state.formFields}
          onSubmitHandler={handleSubmit}
          userFormVisibility={new UserFormVisibility(true, false, false, true, !UserAPI.isAdmin(user), !UserAPI.isAdmin(user))}
          isSaving={state.isSaving}
        />
      </Fragment>
    );
  }
};
