import React, {useContext, useReducer} from 'react';
import {BasketContext} from '../contexts/basket';
import {Box, Button, Grid, Header, Icon, SpaceBetween, Table} from '@amzn/awsui-components-react/polaris';
import {BasketAPI} from '../api/basket';
import {TableItemImage} from '../partials/Item/TableItemImage';
import {TableItemDescription} from '../partials/Item/TableItemDescription';
import {TableItemQuantity} from '../partials/Item/TableItemQuantity';
import {BasketDescription} from '../partials/basket/BasketDescription';

export const BasketListPage = () => {

  const defaultState = {
    isLoading: false
  };

  const [basket, setBasket] = useContext(BasketContext);
  const [state, dispatch] = useReducer(basketListReducer, defaultState);

  function basketListReducer(state, action) {
    switch (action.type) {
      case 'update_basket_request':
        return {
          ...state,
          isLoading: true
        };
      case 'update_basket_success':
        return {
          ...state,
          isLoading: false
        };
      default: break;
    }
  }

  function onClickRemoveItem(id) {
    dispatch({
      type: 'update_basket_request',
      itemId: id
    });
    BasketAPI.removeItemFromBasket(id, updateBasketCallback);
  }

  function onClickEmptyBasket() {
    dispatch({
      type: 'update_basket_request'
    });
    BasketAPI.emptyBasket(updateBasketCallback);
  }

  function updateBasketCallback(response) {
    dispatch({
      type: 'update_basket_success',
      basket: response.basket
    });
    setBasket({items: response.basket});
  }

  return (
    <Grid
      gridDefinition={[
        { colspan: 8, offset: { xxs: 2 } },
      ]}
    >
      <Table
        items={basket.items}
        loading={(state.isLoading || basket.items === undefined)}
        loadingText='Loading basket'
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No items</b>
            <Box
              padding={{bottom: 's'}}
              variant='p'
              color='inherit'
            >
              Basket is empty.
            </Box>
          </Box>
        }
        header={
          <Header
            variant="h2"
            description={BasketDescription(basket)}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={onClickEmptyBasket}>Empty Basket</Button>
              </SpaceBetween>
            }
          >
            Your Basket
          </Header>
        }
        columnDefinitions={[
          {
            id: 'image',
            header: '',
            cell: e => TableItemImage(e.item)
          },
          {
            id: 'name',
            header: 'Name',
            cell: e => TableItemDescription(e.item),
            maxWidth: 400
          },
          {
            id: 'warehouse',
            header: 'Warehouse',
            cell: e => e.item.warehouse,
            width: 100,
          },
          {
            id: 'weight',
            header: 'Weight',
            cell: e => TableItemQuantity(e.quantity, e.item.weight_kg),
            width: 100,
          },
          {
            id: 'number_of_items',
            header: 'Items per pack',
            cell: e => <div className={'right-text'}>{e.item.number_of_items}</div>,
            width: 100
          },
          {
            id: 'units',
            header: 'Units',
            cell: e => <div className={'right-text'}><span className={'mr-10'}>{e.quantity}</span> <Button variant='primary' onClick={() => onClickRemoveItem(e.item.id)}><Icon name='close'/></Button></div>,
            width: 150
          }
        ]}/>
    </Grid>
  );
};