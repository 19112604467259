import {RestAPI as API} from '@aws-amplify/api-rest';

class BasketAPIClass {
  getBasket(callback) {
    API.get('API', '/basket').then(callback);
  }

  removeItemFromBasket(itemId, callback) {
    API.put('API', '/basket', {
      body: {
        item: {
          item_id: itemId,
          quantity: 0
        }
      }
    }).then(callback);
  }

  updateItemQuantity(itemId, quantity, callback, errorCallback) {
    API.put('API', '/basket', {
      body: {
        item: {
          item_id: itemId,
          quantity: quantity
        }
      }
    }).then(callback).catch(errorCallback);
  }

  emptyBasket(callback) {
    API.del('API', '/basket', {}).then(callback);
  }
}

export const BasketAPI = new BasketAPIClass();