import React from 'react';
import {Button, Header, SpaceBetween} from '@amzn/awsui-components-react';
import {UserAPI} from '../../api/user';

export const TableHeader = ({user, itemsCount, unitsCount, onClickBulkAddItems}) => {

  function getTitle() {
    if (UserAPI.isAdmin(user)) {
      return itemsCount + ' ASIN' + (itemsCount > 0 ? 's' : '') + ' / ' + unitsCount + ' unit' + (unitsCount > 0 ? 's' : '');
    }
    return 'Items';
  }

  return (
    <Header
      variant="h2"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <div className='center-text'>
            <Button variant='primary' disabled={itemsCount > 1000} onClick={onClickBulkAddItems}>Add all items</Button>
            {itemsCount > 1000 ? <div>(Too many items visible)</div> : <span/>}
          </div>
        </SpaceBetween>
      }
    >
      {getTitle()}
    </Header>
  );
};