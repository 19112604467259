import React from 'react';
import sopEnglish from './sop_english.json';
import sopGerman from './sop_german.json';
import {Header} from '@amzn/awsui-components-react';
import {SOPChapter} from './SOPChapter';

export const SOP = ({language}) => {

  const mapping = {
    'en': sopEnglish,
    'de': sopGerman
  };
  const sopContent = mapping[language] ?? sopEnglish;

  return (
    <div>
      <Header description={sopContent.description} variant={'h2'}>{sopContent.header_title}</Header>
      {
        sopContent.chapters.map(chapter => <SOPChapter chapter={chapter}/>)
      }
    </div>
  );
};