import React from 'react';

export const TableItemDescription = (item) => {
  return <div>
    {item.is_new_from_lw ? <span className={'new-from-lw-badge'}><span className={'new-from-lw-badge-text'}>New</span></span> : <span/>}
    <div>
      {item.is_removed ? <span>[Deleted]<strike>{item.item_name}</strike></span>: item.item_name}
    </div>
    <em>{item.product_group_description} / {item.category_description} {['nan', 'Unknown'].indexOf(item.subcategory_description) === -1 ? '/ ' + item.subcategory_description : ''}</em>
  </div>;
};