import {RestAPI as API} from '@aws-amplify/api-rest';


export class UserAPIClass {
  getBusinessGroups(callback) {
    API.get('API', '/profile')
      .then(callback);
  }

  getUser(callback) {
    API.get('API', '/profile').then(callback);
  }

  updateUser(formFields, callback) {
    API.post('API', '/profile', {
      body: {
        first_name: formFields.firstNameField,
        last_name: formFields.lastNameField,
        email: formFields.email,
        business_groups: formFields.businessGroups.map(businessGroup => ({
          type: businessGroup.typeSubmit,
          value: businessGroup.valueSubmit
        })),
        units_limit: formFields.unitsLimitField,
        cogs_limit: formFields.cogsLimitField
      }
    })
      .then(callback);
  }

  isAdmin(user) {
    if (user === undefined) {
      return false;
    }
    return user.permission_group === 'admin';
  }
}

export const UserAPI = new UserAPIClass();