import React, {useEffect, useState} from 'react';
import {BasketAPI} from '../api/basket';

const BasketContext = React.createContext([{items: []}, () => {}]);

const BasketProvider = (props) => {
  const [state, setState] = useState({items: []});

  useEffect(() => {
    BasketAPI.getBasket((response) => {
      setState({items: response});
    });
  }, []);

  return (
    <BasketContext.Provider value={[state, setState]}>
      {props.children}
    </BasketContext.Provider>
  );
};

export { BasketContext, BasketProvider };