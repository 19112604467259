import React, {Fragment} from 'react';
import {ItemsManagePage} from './ItemsManagePage';
import {CharityListPage} from './CharityListPage';
import {Grid, SpaceBetween} from '@amzn/awsui-components-react';


export const HomeManagePage = () => {
  return (
    <Fragment>
      <Grid
        gridDefinition={[
          { colspan: 10, offset: { xxs: 1 } },
        ]}
      >
        <SpaceBetween size={'s'} direction={'vertical'}>
          <ItemsManagePage/>
          <CharityListPage/>
        </SpaceBetween>
      </Grid>
    </Fragment>
  );
};