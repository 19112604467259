import {RestAPI as API} from '@aws-amplify/api-rest';
import axios from 'axios';
import {Auth} from '@aws-amplify/auth';
import amplify from '../amplify';


export class ItemsAPIClass {
  createQueryStringParametersForItems(state) {
    let queryStringParameters = {
      warehouse: state.businessGroupsChecked['FC'],
      page: state.currentPageIndex,
      product_group: state.productGroupsSelected
    };
    if (state.filteringText.length > 0) {
      queryStringParameters['filtering_text'] = state.filteringText;
    }
    return queryStringParameters;
  }

  bulkAddItems(state, callback) {
    API
      .post(
        'API',
        '/basket',
        {queryStringParameters: this.createQueryStringParametersForItems(state)}
      )
      .then(callback);
  }

  getAvailableFilters(state, callback) {
    API
      .get(
        'API',
        '/items/filters',
        {queryStringParameters: this.createQueryStringParametersForItems(state)}
      )
      .then(callback);
  }

  getItems(state, callback) {
    API
      .get(
        'API',
        '/items',
        {queryStringParameters: this.createQueryStringParametersForItems(state)}
      )
      .then(callback);
  }

  getItemsStatus(callback) {
    API
      .get('API', '/items/manage')
      .then(callback);
  }

  setItemsActive(snapshotDate, countryCode, isActive, type, callback) {
    API.post('API', '/items/manage', {
      body: {
        action: type,
        snapshot_date: snapshotDate,
        country_code: countryCode,
        is_active: isActive
      }
    }).then(callback);
  }

  removeItems(snapshotDate, countryCode, asins, callback) {
    API.del('API', '/items/manage', {
      body: {
        action: 'remove',
        snapshot_date: snapshotDate,
        country_code: countryCode,
        asins: asins
      }
    }).then(callback);
  }

  recoverItems(snapshotDate, countryCode, asins, callback) {
    API.del('API', '/items/manage', {
      body: {
        action: 'recover',
        snapshot_date: snapshotDate,
        country_code: countryCode,
        asins: asins
      }
    }).then(callback);
  }

  setItemsExpirationDate(snapshotDate, countryCode, expirationDate, callback) {
    API.post('API', '/items/manage', {
      body: {
        action: 'set_expiration',
        snapshot_date: snapshotDate,
        country_code: countryCode,
        expiration_date: expirationDate
      }
    }).then(callback);
  }

  async exportBasket(snapshotDate, countryCode, exportType, callback, callbackError) {
    let params = {
      country_code: countryCode,
      export_type: exportType
    };
    let filename = 'DOREA_' + countryCode + '.csv';
    if (snapshotDate !== null) {
      params['snapshot_date'] = snapshotDate;
      filename = 'DOREA_' + countryCode + '_' + snapshotDate.toLocaleString() + '.csv';
    }
    axios({
      url: amplify.API.endpoints[0].endpoint + '/items/export',
      method: 'GET',
      responseType: 'blob', // important
      params: params,
      headers: {
        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).then(callback);
  }

  listManualUploadsAvailable(callback) {
    API
      .get('API', '/items/manage/manual')
      .then(callback);
  }

  uploadManualFile(filename, callback) {
    API
      .put('API', '/items/manage/manual', {
        body: {
          filename: filename
        }
      }).then(callback);
  }

  emptyManualUpload(countryCode, callback) {
    API
      .del('API', '/items/manage/manual', {
        body: {
          country_code: countryCode
        }
      }).then(callback);
  }

  setBasketExpired(countryCode, isExpired, callback) {
    API
      .post('API', '/items/manage/manual', {
        body: {
          action: 'set_basket_expired_manual',
          country_code: countryCode,
          is_expired: isExpired
        }
      }).then(callback);
  }
}

export const ItemsAPI = new ItemsAPIClass();