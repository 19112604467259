import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import {Box, FormField, SpaceBetween, Textarea} from '@amzn/awsui-components-react';
import {Button} from '@amzn/awsui-components-react/polaris';
import {useState} from 'react';

export const ASINModal = ({ASINModalProps, onClickASINModalDismiss, removeASINs, recoverASINs}) => {

  const [ASINs, setASINs] = useState('');

  function onClickRemoveASINs() {
    const ASINsClean = ASINs.split('\n').map(asin => asin.trim());
    removeASINs(ASINModalProps, ASINsClean);
  }

  function onClickRecoverASINs() {
    const ASINsClean = ASINs.split('\n').map(asin => asin.trim());
    recoverASINs(ASINModalProps, ASINsClean);
  }

  return (
    <Modal
      onDismiss={() => onClickASINModalDismiss(false)}
      visible={ASINModalProps.visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onClickASINModalDismiss(false)}>Cancel</Button>
            <Button variant="primary" onClick={onClickRecoverASINs} loading={ASINModalProps.isLoading}>Undelete</Button>
            <Button variant="primary" onClick={onClickRemoveASINs} loading={ASINModalProps.isLoading}>Delete</Button>
          </SpaceBetween>
        </Box>
      }
      header="Edit ASINs list"
    >
      <FormField
        label="ASINs"
        description="Enter your ASINs list, line-separated"
      >
        <Textarea
          onChange={({ detail }) => setASINs(detail.value)}
          value={ASINs}
          placeholder="B09G941HK9&#10;B09HHGT5SS"/>
      </FormField>
    </Modal>
  );
};