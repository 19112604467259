import React from 'react';
import {Link} from 'react-router-dom';

export const ShoppingBasket = ({quantity}) => {
  return (
    <div className='basket-container'>
      <Link to='/basket'>
        <img src='img/shopping-cart.png' alt={'shopping-cart'} className={'basket-image'}/>
        <span className='shopping-basket-navbar-quantity'>{quantity}</span>
      </Link>
    </div>
  );
};