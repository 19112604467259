import React from 'react';
import {Link} from 'react-router-dom';

/**
 * Bottom navigation footer.
 */
export const Footer = () => (
  <footer id="footer">
    <div className='awsui-row'>
      <span className='text-white'>
        <Link to={'/help'}>Help</Link>
      </span>
    </div>
  </footer>
);
