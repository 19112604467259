import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {AppLayout, Spinner} from '@amzn/awsui-components-react';
import {Amplify, Hub} from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import {NavBar} from './NavBar';
import {Footer} from './Footer';
import config from './amplify';
import './App.css';
import '@amzn/awsui-global-styles/polaris.css';
import {ProfilePage} from './profile/ProfilePage';
import {RestAPI as API} from '@aws-amplify/api-rest';
import {CharityNewPage} from './manage/CharityNewPage';
import {CharityListPage} from './manage/CharityListPage';
import {CharityEditPage} from './manage/CharityEditPage';
import {ItemsManagePage} from './manage/ItemsManagePage';
import {ItemsListPage} from './items/ItemsListPage';
import {HomeManagePage} from './manage/HomeManagePage';
import {BasketProvider} from './contexts/basket';
import {BasketListPage} from './basket/BasketListPage';
import {HelpPage} from './help/HelpPage';

Amplify.configure(config);

/**
 * Main App component
 */
export const App = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Hub.listen('auth', ({payload: {event, data}}) => {
      setIsLoading(true);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(getUserFromAPI).then(userData => {
            setUser(userData);
            setIsLoading(false);
          });
          break;
        case 'signOut':
          setUser(null);
          setIsLoading(false);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          setIsLoading(false);
          break;
        default:
          setUser(null);
          setIsLoading(false);
      }
    });
    getUser().then(getUserFromAPI).then(userData => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .catch(() => Auth.signOut());
  }

  function getUserFromAPI() {
    return API.get('API', '/profile')
      .then(userData => userData)
      .catch(() => Auth.signOut());
  }

  if (user) {
    return (
      <Router>
        <BasketProvider>
          <NavBar user={user}/>
          <div className="awsui">
            <AppLayout
              footerSelector='#footer'
              headerSelector="#navbar"
              navigationHide={true}
              toolsHide={true}
              content={
                <Switch>
                  <Route exact path="/" component={() => (<ItemsListPage user={user} />)}/>
                  <Route exact path='/manage/charities' component={CharityListPage}/>
                  <Route exact path='/manage/charity/new' component={CharityNewPage}/>
                  <Route exact path="/manage/items" component={ItemsManagePage}/>
                  <Route exact path="/help" component={HelpPage}/>
                  <Route path='/manage/charity/:username' component={CharityEditPage}/>
                  <Route path="/manage" component={HomeManagePage}/>
                  <Route path="/profile"  component={() => (<ProfilePage user={user} />)}/>
                  <Route path="/basket" component={BasketListPage}/>
                </Switch>}
            />
          </div>
        </BasketProvider>
        <Footer/>
      </Router>
    );
  } else if (isLoading) {
    return (
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={<div className='center-text'><Spinner size='large' variant='normal'/></div>}
      />
    );
  } else {
    return (
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={<Redirect to={Auth.federatedSignIn()}/>}
      />
    );
  }
};
