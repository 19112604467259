import React from 'react';
import {ExpandableSection, Header} from '@amzn/awsui-components-react';

export const InformationHeader = ({feedbackDate}) => {

  function formatDatetimeToDate(date) {
    return new Date(date).toDateString();
  }

  return <div className={feedbackDate !== undefined ? 'visible': 'hidden'}>
    <ExpandableSection
      variant="container"
      header={
        <Header variant="h2">
          Information
        </Header>
      }
    >
      Feedback by {formatDatetimeToDate(feedbackDate)}
    </ExpandableSection>
  </div>;
};