
import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import {Box, SpaceBetween} from '@amzn/awsui-components-react';
import {Button, Select} from '@amzn/awsui-components-react/polaris';
import {useEffect, useReducer} from 'react';
import {ItemsAPI} from '../../api/items';

export const S3FileChoiceModal = ({S3FileChoiceModalProps, onClickS3FileChoiceModalDismiss, onClickS3FileChoiceModalValidate}) => {

  const defaultState = {
    statusType: 'loading',
    filenameOptions: [],
    filenameSelected: null
  };

  function s3FileChoiceModalReducer(state, action) {
    switch (action.type) {
      case 'fetch_manual_uploads_available_request':
        return {
          ...state,
          statusType: 'loading'
        };
      case 'fetch_manual_uploads_available_success':
        return {
          ...state,
          statusType: '',
          filenameOptions: action.objects.map(object => {
            return {'label': object.Key, 'value': object.Key};
          })
        };
      case 'filename_selected_change':
        return {
          ...state,
          filenameSelected: action.selectedOption
        };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(s3FileChoiceModalReducer, defaultState);

  function listManualUploadsAvailable() {
    dispatch({
      type: 'fetch_manual_uploads_available_request'
    });
    ItemsAPI.listManualUploadsAvailable((response) => {
      dispatch({
        type: 'fetch_manual_uploads_available_success',
        objects: response
      });
    });
  }

  function onChangeFilenameSelected(selectedOption) {
    dispatch({
      type: 'filename_selected_change',
      selectedOption: selectedOption
    });
  }

  useEffect(() => {
    listManualUploadsAvailable();
  }, []);


  return (
    <Modal
      onDismiss={() => onClickS3FileChoiceModalDismiss(false)}
      visible={S3FileChoiceModalProps.visible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onClickS3FileChoiceModalDismiss(false)}>Cancel</Button>
            <Button variant="primary" onClick={() => onClickS3FileChoiceModalValidate(state.filenameSelected.value)} loading={S3FileChoiceModalProps.isUploading}>Validate</Button>
          </SpaceBetween>
        </Box>
      }
      header="Choose your file"
    >
      <Select
        selectedOption={state.filenameSelected}
        onChange={({ detail }) =>
          onChangeFilenameSelected(detail.selectedOption)
        }
        options={state.filenameOptions}
        selectedAriaLabel="Selected"
        statusType={state.statusType}
      />
    </Modal>
  );
};