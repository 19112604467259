import React, {Fragment} from 'react';
import {Grid, Header} from '@amzn/awsui-components-react';


export const SOPChapter = ({chapter}) => {
  const hasImages = chapter.images !== undefined;

  if (hasImages) {
    return (
      <Fragment>
        <Header variant={'h2'}>
          {chapter.title}
        </Header>
        <Grid gridDefinition={[
          { colspan: 5}, {colspan: 7}
        ]}>
          <div>
            {
              chapter.images.map(image => {
                return <img className={'img-fit-grid'} src={image} alt={image}/>;
              })
            }
          </div>
          <div>
            <ul>
              {
                chapter.steps.map(step => {
                  if (step.bullet_points !== undefined) {
                    return <li>
                      {step.text}
                      <ul>
                        {
                          step.bullet_points.map(bulletPoint => {
                            return <li>{bulletPoint}</li>;
                          })
                        }
                      </ul>
                    </li>;
                  } else {
                    return <li>{step.text}</li>;
                  }
                })
              }
            </ul>
          </div>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header variant={'h2'}>
          {chapter.title}
        </Header>
        <div>
          <ul>
            {
              chapter.steps.map(step => {
                if (step.bullet_points !== undefined) {
                  return <li>
                    {step.text}
                    <ul>
                      {
                        step.bullet_points.map(bulletPoint => {
                          return <li>{bulletPoint}</li>;
                        })
                      }
                    </ul>
                  </li>;
                } else {
                  return <li>{step.text}</li>;
                }
              })
            }
          </ul>
        </div>
      </Fragment>
    );
  }
};