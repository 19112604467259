import React, {useState} from 'react';
import {Checkbox} from '@amzn/awsui-components-react';

export const Filter = ({label, isSelected, onChange}) => {
  const [checked, setChecked] = useState(isSelected);

  function onCheckboxChange(isChecked) {
    setChecked(isChecked);
    onChange(label, isChecked);
  }

  return (
    <Checkbox
      onChange={({ detail }) =>
        onCheckboxChange(detail.checked)
      }
      checked={checked}
    >
      {label}
    </Checkbox>
  );
};