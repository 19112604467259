import React from 'react';
import {Icon} from '@amzn/awsui-components-react';

export const TableItemAmazonLink = (item) => {

  function getDomainExtension(item) {
    switch (item.country_code) {
      case 'FR':
        return 'fr';
      case 'DE':
        return 'de';
      case 'ES':
        return 'es';
      case 'IT':
        return 'it';
      default:
        return 'com';
    }
  }

  return <div className='center-text'>
    <a href={'https://amazon.' + getDomainExtension(item) + '/dp/' + item.asin} target='_blank' rel="noopener noreferrer" >
      <Icon name="external" size="normal" variant="normal"/></a>
  </div>;
};