import {RestAPI as API} from '@aws-amplify/api-rest';


export class CharityAPIClass {
  createCharity(formFields, callback, callbackError) {
    API.put('API', '/charity', {
      body: {
        first_name: formFields.firstNameField,
        last_name: formFields.lastNameField,
        vendor_code: formFields.vendorCodeField,
        username: formFields.usernameField,
        email: formFields.emailField,
        is_active: formFields.isActive,
        business_groups: formFields.businessGroups.map(businessGroup => ({
          type: businessGroup.typeSubmit,
          value: businessGroup.valueSubmit
        })),
        units_limit: formFields.unitsLimitField,
        cogs_limit: formFields.cogsLimitField
      }
    })
      .then(callback)
      .catch(callbackError);
  }

  getCharities(callback) {
    API.get('API', '/charities').then(callback);
  }

  getCharity(username, callback) {
    API.get('API', '/charity/' + username).then(callback);
  }

  updateCharity(formFields, callback, callbackError) {
    API.post('API', '/charity/' + formFields.usernameField, {
      body: {
        first_name: formFields.firstNameField,
        last_name: formFields.lastNameField,
        vendor_code: formFields.vendorCodeField,
        username: formFields.usernameField,
        email: formFields.emailField,
        is_active: formFields.isActive,
        business_groups: formFields.businessGroups.map(businessGroup => ({
          type: businessGroup.typeSubmit,
          value: businessGroup.valueSubmit
        })),
        units_limit: formFields.unitsLimitField,
        cogs_limit: formFields.cogsLimitField
      }
    })
      .then(callback)
      .catch(callbackError);
  }

  deleteCharity(username, callback) {
    API.del('API', '/charity/' + username).then(callback);
  }
}

export const CharityAPI = new CharityAPIClass();